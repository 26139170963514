import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Routes from "./routes/Routes";
import Header from './components/header';
import { Footer } from './components/footer';
import { fetchProducts } from './actions/productAction'
import { fetchCategories } from './actions/categoryAction'
import { connect } from 'react-redux';
import store from './store';
import { syncQuantity } from './actions/catalogActions';
import { addToCart } from './actions/cartActions';
import { getCookie } from "./helpers/cookie-helper";
import { CUSTOMER_COOKIE, ENVIRONMENT, ALLOWED_HOSTS,
    SEND_STATISTICS, CART_STATE_PERSIST, PRIVACY_POLICY, USER_DATA , 
    COOKIE_NAME, ACCEPT_SCRIPT_URL, SCRIPT_TYPE, SCRIPT_CHARSET, SCRIPT,
    ALL} from "./helpers/constants";
import { setCustomerFromCookie, saveRecord } from "./actions/customerAction";
import { isLoggedIn, checkAccess } from "./actions/globalActions";
import { getOrders } from "./actions/orderAction";
import { parseFreeAccessories } from "./helpers/utility";
import { CookieView } from "./components/cookieView";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cartItems: JSON.parse(localStorage.getItem(CART_STATE_PERSIST)) || [],
            page : false,
            allowAccess: false
        }

    }
    componentWillMount() {
        const allowedHost = ALLOWED_HOSTS[ENVIRONMENT.toLowerCase()] || ALLOWED_HOSTS.production;
        const hostname = window.location.hostname;
        if (hostname === allowedHost) {
            this.setState({ allowAccess: true });
            store.dispatch(fetchProducts())
            store.dispatch(fetchCategories());
        }
    }
    componentDidMount = () => {
        let getCookieValue = getCookie(COOKIE_NAME);
		
        if(getCookieValue){
            const script = document.createElement(SCRIPT);
            script.src = ACCEPT_SCRIPT_URL;
            script.type = SCRIPT_TYPE;
            script.charset = SCRIPT_CHARSET;
            document.body.appendChild(script);
            store.dispatch(checkAccess())
            
            var thisObj = this;
            var intervalId = setInterval(function () {
                if (store.getState().product.products.length > 0) {
                    clearInterval(intervalId);
                    thisObj.state.cartItems.forEach(function (item) {
                        var index = store.getState().product.products.findIndex(i => i.ProductCode === item.ProductCode)
                        if (index > -1) {
                            var syncCatalog = {
                                ProductCode: item.ProductCode,
                                Quantity: item.Quantity
                            }
                            let product = store.getState().product.products[index];
                            product.Quantity = item.Quantity
                            product.SelectedOptions = item.SelectedOptions
                            product.Childs = parseFreeAccessories(product, store.getState().product.products)
                            // for (const key in product.SelectedOptions) {
                            //     if (product.SelectedOptions[key].ProductPrice > 0) {
                            //         product.ProductPrice += product.SelectedOptions[key].ProductPrice
                            //     }
                            // }
                            store.dispatch(addToCart(product));
                            store.dispatch(syncQuantity(syncCatalog));
                            thisObj.setState({ test: 123 })
                        }
                    })
                }
            }, 10);
            let customer = getCookie(CUSTOMER_COOKIE, true)
            // console.log(customer)
            if (customer !== undefined && customer.code && parseInt(customer.code) === 200) {
                store.dispatch(setCustomerFromCookie(customer))
                store.dispatch(getOrders(customer.result));
                store.dispatch(isLoggedIn(true))
            } else {
                store.dispatch(isLoggedIn(false))
            }
        }
    }

    componentDidUpdate = (prevProps) => {
        // if (this.props.ip !== prevProps.ip) {
            let page = (window.location.href.split('/')).includes(PRIVACY_POLICY);
            if(page !== this.state.page){
                this.setState({
                    page:page
                })
            }
            let customer = getCookie(CUSTOMER_COOKIE, true)
            let currentUrl = window.location.href.replace(/^.*\/\/[^\/]+/, '')
            if (customer) {
                let userData = { firstname: customer.result.FirstName, lastname: customer.result.LastName, email: customer.result.EmailAddress, userId: customer.result.CustomerID, ipAddress: store.getState().customer.ip, lastModifyOn: new Date, url: currentUrl, type: USER_DATA.type }
                store.dispatch(saveRecord(userData))
            } else {
                let userData = { firstname: USER_DATA.name, lastname: USER_DATA.name, email: null, userId: null, ipAddress: store.getState().customer.ip, lastModifyOn: new Date, url: currentUrl, type: USER_DATA.type }
                store.dispatch(saveRecord(userData))
            }
        // }
    }

    componentWillReceiveProps(props) {
        // console.log("nnnwe props", props)
        this.props = props
    }
    render() {
        const { allowAccess } = this.state;
        if (!allowAccess) {
            return null;
        }
        let localStatisticsData = localStorage.getItem(SEND_STATISTICS);
        return (
            <div id={ALL}>
                <Header history={this.props.history} />
                <Routes />
                {(!this.state.page || !localStatisticsData ) && <CookieView/>}
                <Footer />
            </div>
        );
    }
}
const stateMapApp = (state) => {
    return {
        products: state.product.products,
        ip: state.customer.ip
    };
};
connect(stateMapApp)(App);


export default withRouter(App);
